import { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import images from "../../../constants/images";
export type PriceFilter = {
  minPrice: number;
  maxPrice: number;
};

const ReactSliderComponent = ReactSlider as any;

type PriceRangeFilterProps = {
  savePriceFilter: (priceFilter: PriceFilter) => void;
  maxPrice: number;
  selectedPrices: { minPrice: number; maxPrice: number };
};

export const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({
  savePriceFilter,
  maxPrice,
  selectedPrices,
}) => {
  const [showBody, setShowBody] = useState<boolean>(true);
  const [priceFilter, setPriceFilter] = useState<PriceFilter>({
    minPrice: selectedPrices.minPrice || 0,
    maxPrice: selectedPrices.maxPrice || 500,
  });

  const handlePriceChange = (values: number[]) => {
    setPriceFilter({ minPrice: values[0], maxPrice: values[1] });
  };

  useEffect(() => {
    savePriceFilter(priceFilter);
  }, [priceFilter]);

  return (
    <div>
      <div className="flex justify-between border-t-2 border-y-slate-200 py-2">
        <div>PRICE</div>
        <img
          src={showBody ? images.caretUp : images.caretDown}
          onClick={() => setShowBody(!showBody)}
        />
      </div>
      {showBody && (
        <div className="py-4 space-y-2 border-t-2 border-y-slate-100">
          <div className=" p-4">
            <ReactSliderComponent
              className="horizontal-slider"
              withTracks
              thumbClassName="bg-orange-500 rounded-full text-center border-2 border-white text-white text-sm w-4 h-4 leading-4 focus:outline-white drop-shadow-md"
              trackClassName={`bg-orange-200 h-1 rounded-full`}
              min={0}
              max={maxPrice || 500}
              defaultValue={[priceFilter.minPrice, priceFilter.maxPrice]}
              onChange={handlePriceChange}
              value={[selectedPrices.minPrice, selectedPrices.maxPrice]}
              ariaLabelledby={["minPrice", "maxPrice"]}
              ariaValuetext={(state: any) => `Thumb value ${state.valueNow}`}
              renderThumb={(props: any, state: any) => (
                <div
                  {...props}
                  className={`${props.className} -translate-y-1`}
                ></div>
              )}
              pearling
              renderTrack={(props: any, state: any) => (
                <div
                  {...props}
                  className={`${props.className} ${
                    state.index === 1 && "bg-orange-400"
                  }`}
                />
              )}
            />
          </div>
          <div className="flex m-2 justify-between">
            <div className="border-2 border-slate-200 w-1/2 mx-2 p-2 flex text-center rounded-lg text-sm">
              $<span className="text-slate-500">min: </span>
              <input
                type="number"
                id="minPrice"
                name="minPrice"
                className="w-full h-full py-0 border-none focus:border-none focus:shadow-none focus:ring-0 focus:outline-none"
                value={selectedPrices.minPrice}
                onChange={(e) =>
                  setPriceFilter({ ...priceFilter, minPrice: +e.target.value })
                }
              />
            </div>
            <div className="border-2 border-slate-200 w-1/2 mx-2 p-2 flex text-center rounded-lg text-sm">
              $<span className="text-slate-500">max: </span>
              <input
                type="number"
                id="maxPrice"
                name="maxPrice"
                className="w-full h-full py-0 border-none focus:border-none focus:shadow-none focus:ring-0 focus:outline-none"
                value={selectedPrices.maxPrice}
                onChange={(e) =>
                  setPriceFilter({ ...priceFilter, maxPrice: +e.target.value })
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
