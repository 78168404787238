import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CorpContext } from "../../context/Corp.context";
import { DragAndDrop } from "../common/DragAndDrop";
import { SearchTable } from "../common/SearchTable";

type propType = {
  title: string;
};

export const EditCorpPage = (props: propType) => {
  const { title } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { corp, setCorp, setCorpId } = useContext(CorpContext);
  const [collectionTags, setCollectionTags] = useState<number[]>([]);
  const [customPlan, setCustomPlan] = useState({
    defaultPlanId: 0,
    name: "",
    description: "",
    isActive: true,
    isAnnual: true,
    listPrice: 0,
    discount: 0,
    discountAb: "",
    actualPrice: 0,
    renualPrice: 0,
    deliveryCharge: 0,
  });
  const [addresses, setAddresses] = useState([
    {
      street: "",
      address: "",
      city: "",
      postalCode: "",
    },
  ]);

  const [showConfirmAddBalance, setShowConfirmAddBalance] = useState(false);
  const [addBalanceValue, setAddBalanceValue] = useState<string>("");
  const [isAddingBalance, setIsAddingBalance] = useState(false);

  const fromPage = location.state?.fromPage || 1;
  const handleCollection = (idArr: number[]) => {
    setCollectionTags(idArr);
  };

  const handleAddBalance = (value: number) => {
    if (isNaN(value)) {
      toast.error("The value must be a number");
      return;
    }

    setIsAddingBalance(true);
    if (corp.id) {
      let payload = { corpId: corp.id, amount: value, type: "cr" };

      axios
        .post(
          `${process.env.REACT_APP_CORP_URL}/api/corp/transaction/wallet`,
          payload,
        )
        .then(() => {
          setShowConfirmAddBalance(false);
          setAddBalanceValue("");
          setCorp((prevCorp) => ({
            ...prevCorp,
            balance: (prevCorp.balance ?? 0) + value,
          }));
          toast.success("Successfully added balance");
        })
        .catch((error) => toast.error(error))
        .finally(() => setIsAddingBalance(false));
    } else {
      toast.error("Corp Id is missing or invalid");
    }
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_CORP_URL}/api/corp/${id}`)
        .then(({ data }) => {
          setCorp(data.data);
          setAddresses(
            Array.isArray(data.data.addresses)
              ? data.data.addresses
              : addresses,
          );
          setCustomPlan(data.data.customPlan || customPlan);
          setCollectionTags(
            Array.isArray(data.data.collection)
              ? data.data.collection.map(
                  (collectionTag: { collection: { id: any } }) =>
                    collectionTag.collection.id,
                )
              : [],
          );
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [id, setCorp]);

  const handleCustomPlanChange = (e: any) => {
    const { name, value } = e.target;
    setCustomPlan((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCorp((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddMore = () => {
    setAddresses((prev) => [
      ...prev,
      { street: "", address: "", city: "", postalCode: "" },
    ]);
  };

  const handleDelete = (index: number) => {
    if (addresses.length === 1) {
      alert("Cannot delete the last address.");
      return;
    }
    setAddresses((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const newAddresses = addresses.map((address, i) =>
      i === index ? { ...address, [name]: value } : address,
    );
    setAddresses(newAddresses);
  };

  const saveCorp = async () => {
    const payload = { ...corp, collectionTags, addresses, customPlan };

    const url = `${process.env.REACT_APP_ADMIN_URL}/corp/${id}`;

    axios
      .put(url, payload)
      .then((res) => {
        toast.success("Corp updated successfully");
        navigate("/corps", { state: { page: fromPage } });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const getAzureImageUrl = (imgUrlArray: string[]) => {
    setCorp((prevCorp) => ({ ...prevCorp, logo: imgUrlArray[0] })); // use first element for one logo
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">{title}</h1>
        <div className="flex space-x-2">
          <button
            className="px-4 py-2 rounded-lg text-gray-600 border border-gray-300"
            onClick={() => navigate("/corps", { state: { page: fromPage } })}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-lg text-white bg-gray-800"
            onClick={saveCorp}
          >
            Save
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div className="md:col-span-7 space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Media</h2>
            <DragAndDrop
              getAzureImageUrl={getAzureImageUrl}
              updateImgUrl={[corp.logo || ""]}
              numOfImages={1}
            />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Corp Details</h2>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={corp.firstName || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Middle Name
                </label>
                <input
                  type="text"
                  name="middleName"
                  value={corp.middleName || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Middle Name"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={corp.lastName || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Organization Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="organisationName"
                  value={corp.organisationName || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Organization Name"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={corp.phoneNumber || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Phone Number"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={corp.email || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Email"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Government ID
                </label>
                <input
                  type="text"
                  name="govId"
                  value={corp.govId || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Government ID"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Tax ID
                </label>
                <input
                  type="text"
                  name="taxId"
                  value={corp.taxId || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Tax ID"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Referral ID
                </label>
                <input
                  type="text"
                  name="referralId"
                  value={corp.referralId || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Referral ID"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Government ID Type
                </label>
                <input
                  type="text"
                  name="govIdType"
                  value={corp.govIdType || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Government ID Type"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Template
                </label>
                <input
                  type="text"
                  name="template"
                  value={corp.template || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Template"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Employee Range
                </label>
                <input
                  type="text"
                  name="employeeRange"
                  value={corp.employeeRange || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Employee Range"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Credit Conversion Ratio
                </label>
                <input
                  type="number"
                  name="creditConversationRatio"
                  value={corp.creditConversationRatio || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Credit Conversion Ratio"
                  onChange={handleInputChange}
                />
              </div>
              <br></br>

              <div className="flex flex-col items-start">
                <div className="font-bold ">Add balance</div>
                <input
                  value={addBalanceValue}
                  type="number"
                  placeholder="Enter amount..."
                  className="border rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
                  onChange={(e) => setAddBalanceValue(e.target.value)}
                />
                <button
                  className="self-start my-2 px-5 p-2 bg-green-400 text-sm border border-orange text-white rounded-lg"
                  onClick={(e) => {
                    if (addBalanceValue) {
                      setShowConfirmAddBalance(true);
                    } else {
                      toast.error(
                        "Invalid add balance value: " + addBalanceValue,
                      );
                    }
                  }}
                >
                  Add
                </button>
                <div>
                  <label className=" text-sm font-medium text-gray-700">
                    Balance -
                  </label>
                  <span className="text-md "> ${corp.balance || ""}</span>
                </div>
              </div>
              {showConfirmAddBalance && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-6 rounded-md shadow-md w-96">
                    <h2 className="text-xl font-semibold mb-4">
                      Confirm Transaction
                    </h2>
                    <div>Current balance: {corp.balance}</div>
                    <div>
                      After transaction balance:
                      {Number(addBalanceValue) + (corp.balance ?? 0)}
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600 disabled:bg-slate-500"
                        onClick={() => {
                          setShowConfirmAddBalance(false);
                        }}
                        disabled={isAddingBalance}
                      >
                        Cancel
                      </button>
                      <button
                        className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 disabled:bg-slate-500"
                        onClick={() => {
                          handleAddBalance(Number(addBalanceValue));
                        }}
                        disabled={isAddingBalance}
                      >
                        {isAddingBalance ? "Adding balance..." : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Custom Plan</h2>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Default Plan ID
                </label>
                <input
                  type="number"
                  name="defaultPlanId"
                  value={customPlan.defaultPlanId || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Default Plan ID"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={customPlan.name || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Name"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={customPlan.description || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Description"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  List Price
                </label>
                <input
                  type="number"
                  name="listPrice"
                  value={customPlan.listPrice || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="List Price"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Discount
                </label>
                <input
                  type="number"
                  name="discount"
                  value={customPlan.discount || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Discount"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Discount Abbreviation
                </label>
                <input
                  type="text"
                  name="discountAb"
                  value={customPlan.discountAb || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Discount Abbreviation"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Actual Price
                </label>
                <input
                  type="number"
                  name="actualPrice"
                  value={customPlan.actualPrice || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Actual Price"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Renewal Price
                </label>
                <input
                  type="number"
                  name="renualPrice"
                  value={customPlan.renualPrice || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Renewal Price"
                  onChange={handleCustomPlanChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Delivery Charge
                </label>
                <input
                  type="number"
                  name="deliveryCharge"
                  value={customPlan.deliveryCharge || ""}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Delivery Charge"
                  onChange={handleCustomPlanChange}
                />
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Address</h2>
            {addresses.map((address, index) => (
              <div key={index} className="space-y-4">
                <div>
                  <label className="block text-sm mt-3  font-medium text-gray-700">
                    Street
                  </label>
                  <input
                    type="text"
                    name="street"
                    value={address.street}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Street"
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={address.address}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Address"
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={address.city}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="City"
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={address.postalCode}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Postal Code"
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <button
                  type="button"
                  className="border rounded-lg p-2 bg-orange-500  text-white mt-2"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
              </div>
            ))}
            <div className="flex justify-center">
              <button
                type="button"
                className="mt-4 px-4 py-2 bg-orange-500 text-white rounded-lg"
                onClick={handleAddMore}
              >
                Add More
              </button>
            </div>
          </div>
        </div>
        <div className="md:col-span-5">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Collection</h2>
            <SearchTable
              apiURL={`${process.env.REACT_APP_ADMIN_URL}/collections`}
              usage="Collection"
              columnName="name"
              save={handleCollection}
              selectedValues={collectionTags}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
